<script>
import VisitorLevelForm from "@/views/menu/visitorLevel/visitorLevelForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    visitorLevelService: "visitorLevelService",
  },
})
export default class VisitorLevelDetail extends mixins(VisitorLevelForm) {
  formId = "detail-visitorLevel-form";

  get decoratedElements() {
    return this.allReadonlyFields;
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle() {
    return this.translations.pageTitles.visitorLevels_detail;
  }

  async afterCreate() {
    this.model = await this.visitorLevelService.read(this.$route.params.id);
    this.model.mappedDomains = this.model.automaticDomainAssignmentList.map(item => ({ value: item }));
    this.isReady = true;
  }
}
</script>
