import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService",
  },
})
export default class VisitorLevelForm extends mixins(EntityForm) {
  model = {};
  internal_events = [];
  platformLanguages = [];
  domainRegex = /^([\w%-]+[\w%-]+\.)([\w%-]+[\w%-]+)(\.[\w%-][\w%-]+){0,1}$/;

  get elements() {
    return [
      {
        id: "code",
        label: this.translations?.labels?.visitorLevel_form_code,
        type: "text",
        required: true,
        rules: "min:4",
        onAction: this.noSpaces,
      },
      {
        id: "idEvent",
        label: this.translations?.labels?.events,
        type: "select",
        items: this.events,
        returnObject: false,
        disabled: this.disableField && this.disableField.idEvent
      },
      {
        id: "mappedDomains",
        label: this.translations?.labels?.visitorLevel_form_domains,
        headers: [{ id: "value", rules: [(value) => this.domainRule(value)] }],
        type: "tabledata",
        canAddRows: true,
        canRemoveRows: true,
        sortable: false,
        hideHeader: true,
        resetColumns: true,
        onChange: () => {
          this.updateDomainAssignmentList();
        }
    },
      {
        id: "localizations",
        type: "localizations",
        header: this.translations?.labels?.form_localizations,
        languages: this.platformLanguages,
        resetColumns: true,
        elements: [
          {
            id: "name",
            label: this.translations?.labels?.visitorLevel_form_name,
            type: "text",
            required: true,
          },
          {
            id: "imageUri",
            label: this.translations?.labels?.visitorLevel_form_image,
            type: "media",
            mediaType: "image",
          },
        ],
      },
    ];
  }

  domainRule(value){
    const re = this.domainRegex;
    return !!re.test(String(value).toLowerCase()) || "Inserted domain is invalid";
  }

  get events() {
    return this.internal_events.map((i) => {
      return {
        languages: i.languages,
        label: i.code,
        value: i.id,
      };
    });
  }
  
  get helpLink() {
    return this.translations.pageHelp.visitorLevel;
  }

  noSpaces(evt) {
    let charCode = evt.charCode;
    if (charCode === 32) evt.preventDefault();
    return true;
  }

  updateDomainAssignmentList(){
    this.model.automaticDomainAssignmentList = this.model.mappedDomains.map(item => item.value);
  }

  // eslint-disable-next-line no-empty-function
  afterCreate() {
  }

  async created() {
    this.platformLanguages = await this.languageService.list();
    this.internal_events = await this.eventService.list();
    this.internal_events?.unshift({
      idEvent: null,
      code: ""
    });
    this.model.event = this.events.filter(
      (e) => e.value == this.model.idEvent
    )[0];
    this.afterCreate();
  }
}
